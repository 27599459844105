<template>
    <div class="login-page">
        <img class="qiweihu-title" src="../assets/login/qiweihu_title.png">
        <div class="welcome-login">Welcome欢迎登录</div>
        <div class="content-box">
            <div class="left">
                <img class="left-img" src="../assets/login/login_left_img.png">
            </div>
            <div class="right">
                <div class="tabs">
                    <span :class="active===0?'tab active':'tab'" @click="changeTab(0)">微信扫码登录</span>
<!--                    <span  :class="active===1?'tab active':'tab'" @click="changeTab(1)">验证码登录</span>-->
                </div>
                <div v-if="active===0" class="form-box">
                    <vue-qr
                            class="qrcode"
                            :logoSrc="codeUrl"
                            :text="text"
                            :logoScale="50"
                            :margin="10"
                            :correctLevel="3"
                    />
                    <el-button class="refresh-btn" type="primary" icon="el-icon-refresh-right" @click="refresh">刷新二维码</el-button>
                    <div class="scan-tip">
                        <span>打开微信APP</span>
                        <span>点击右上角扫一扫登录</span>
                    </div>
                </div>
                <div v-if="active===1" class="form-box">
                    <div class="input-line">
                        <img class="icon" src="../assets/login/mobile_icon.png">
                        <el-input placeholder="请输入手机号" v-model="form.mobile"></el-input>
                    </div>
                    <p class="error">{{errors.mobile}}</p>
                    <div class="input-line">
                        <img class="icon" src="../assets/login/code_icon.png">
                        <el-input placeholder="请输入验证码" v-model="form.code"></el-input>
                    </div>
                    <p class="error">{{errors.code}}</p>
                    <div class="input-line">
                        <img class="icon" src="../assets/login/verify_icon.png">
                        <div class="drag-box">
                            <DragVerify
                                    ref="dragVerify"
                                    :isPassing.sync="isPassing"
                                    :width="270"
                                    text="请按住滑块拖动"
                                    successText="验证通过"
                                    handlerIcon="el-icon-d-arrow-right"
                                    successIcon="el-icon-circle-check"
                            >
                                <i
                                        v-show="!isPassing"
                                        slot="textBefore"
                                        class="el-icon-lock"
                                ></i>
                            </DragVerify>
                        </div>
                        <el-button type="primary" :disabled="!isPassing">发送验证码</el-button>
                    </div>
                    <el-button class="login-btn" round type="primary" :loading="false">立即登录</el-button>
                </div>
            </div>
        </div>
        <div class="copyright">Copyright©大盘手网 重庆大盘手网络科技有限公司 All Rights Reserved 版权所有 复制必究 <a class="beian-link" target="_blank" href="http://beian.miit.gov.cn">渝ICP备19015980号-6</a></div>
    </div>
</template>

<script>
  import { apiUserInfo, apiAccounts, apiGenWeappLoginToken, apiQueryWeappQrcodeStatus} from "../api/user"
  import VueQr from 'vue-qr'
  import DragVerify from 'vue-drag-verify2';
  export default {
    name: "Login",
    components: {
      VueQr,
      DragVerify
    },
    data () {
      return {
        active: 0,
        codeUrl: '',
        bgSrc: require('../assets/logo.png'),
        text: 'https://www.qiweihu.cn/mini/',
        isPassing: false,
        tempToken: '',
        form: {
          mobile: '',
          code: ''
        },
        errors: {
          mobile: '手机号码格式不正确',
          code: ''
        }
      }
    },
    async mounted () {
       await this.getGenWeappLoginToken()
        this.getQueryWeappQrcodeStatus()
    },
    methods: {
      refresh () {
        this.getGenWeappLoginToken()
      },
      async getGenWeappLoginToken () {
        let res = await apiGenWeappLoginToken()
        if (res.data.code === 0) {
          this.tempToken = JSON.parse(JSON.stringify(res.data.data.token))
          this.text = 'https://www.qiweihu.cn/mini/' + res.data.data.token
        } else {
          this.$message({
            'message': '获取二维码失败，请手动获取',
            'type': 'error'
          })
        }
      },
      async getQueryWeappQrcodeStatus () {
        let res = await apiQueryWeappQrcodeStatus({token: this.tempToken})
        if (res.data.code === 0 && res.data.data.status === 0) {
          setTimeout( () => {
            this.getQueryWeappQrcodeStatus()
          }, 1500)
        } else if (res.data.code === 0 && res.data.data.status === 2) {
          this.$store.commit('SET_TOKEN', res.data.data.token)
          await this.getUserInfo()
          await this.getAccounts()
          await this.getMaxVarieties()
          this.$router.replace('/')
          this.$message({
            message: "欢迎登录",
            type: "success"
          })
        } else {
          await this.getGenWeappLoginToken()
          this.getQueryWeappQrcodeStatus()
          this.$message({
            'message': '登陆失败失败，请重新登录',
            'type': 'error'
          })
        }
      },
      async getUserInfo () {
        let res = await apiUserInfo()
        if (res.data.code === 0 && res.data.data) {
          let userInfo = res.data.data
          if (userInfo.avatar === '' || !userInfo.avatar) {
            userInfo.avatar = '/images/avatar.png'
          } else {
            userInfo.avatar = 'https://api.qiweihu.cn' + userInfo.avatar;
          }
          this.$store.commit('SET_USER', userInfo)
        } else {
          location.reload()
          this.$message({
            'message': '获取用户信息失败，请重新登录',
            'type': 'error'
          })
        }
      },
      async getAccounts () {
        let res = await apiAccounts()
        if (res.data.code === 0 && res.data.data) {
          const accounts = res.data.data
          if (accounts && accounts.length > 0) {
            let effectiveAccounts = []
            let AllAccounts = accounts.map(item => {
              item.name = item.nickname + '(' + item.account_code + ')';
              if (item.last_data_at !== 0 && item.status === 1) {
                item.last_data_at_format = this.$moment(new Date(item.last_data_at * 1000)).format('YYYY-MM-DD')
                effectiveAccounts.push(item)
              }
              return item
            })
            if (effectiveAccounts.length > 0) {
              this.$store.commit('SET_ACCOUNTS', effectiveAccounts)
              this.$store.commit('SET_CUR_ACCOUNT_ID', [effectiveAccounts[0].id])
            }
            this.$store.commit('SET_ALL_ACCOUNTS', AllAccounts)
            this.$store.commit('SET_ANALYSIS_TYPE', 'single')
          }
        } else {
          this.$message({
            'message': '获取账户列表失败，请稍后重试',
            'type': 'error'
          })
        }
      },
      getMaxVarieties () {
        this.$store.dispatch('getMaxVarieties')
      },
      changeTab (tab) {
        this.active = tab
        if (tab === 1) {
          this.isPassing = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .login-page {
        width: 100%;
        height: 100%;
        background: url("../assets/login/login_bg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        .qiweihu-title {
            width: 664px;
            height: 66px;
        }
        .welcome-login{
            height: 24px;
            line-height: 24px;
            font-size: 24px;
            font-weight: 300;
            color: #FFFFFF;
            margin: 20px auto 40px;
        }
        .content-box {
            width: 1162px;
            height: 663px;
            background: #FFFFFF;
            border-radius: 30px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            .left {
                flex: 1;
                .left-img {
                    width: 472px;
                    height: 392px;
                }
            }
            .right {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .form-box {
                    height: 430px;
                    margin: 50px auto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .input-line {
                        width: 430px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        .icon {
                            margin-right: 20px;
                        }
                        .drag-box {
                            margin: 0;
                            border: 1px solid #DCDFE6;
                        }
                    }
                    .error {
                        text-align: left;
                        margin: 0 0 14px 50px;
                        height: 14px;
                        font-size: 14px;
                        color: #FA5A5E;
                    }
                    .login-btn {
                        margin: 50px 0 0;
                        width: 430px;
                        height: 50px;
                        border-radius: 30px;
                        font-size: 26px;
                        letter-spacing: 4px;
                        background-color: #1777FF;
                    }
                }
                .tabs {
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .tab {
                        text-align: center;
                        width: 150px;
                        height: 46px;
                        line-height: 46px;
                        font-size: 24px;
                        font-weight: bold;
                        color: #666666;
                        position: relative;
                        cursor: pointer;
                        &.active {
                            color: #1777FF;
                            &:after {
                                content: '';
                                position: absolute;
                                width: 150px;
                                height: 3px;
                                left: 0;
                                bottom: 0;
                                background: #1777FF;
                            }
                        }
                    }
                }
                .qrcode {
                    width: 270px;
                    height: 270px;
                    box-shadow: 0 8px 19px 0 rgba(23, 119, 255, 0.13);
                }
                .refresh-btn {
                    margin: 20px auto;
                }
                .scan-tip {
                    font-size: 18px;
                    letter-spacing: 1px;
                    font-weight: 400;
                    color: #999999;
                    line-height: 28px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .drag-box {
                    width: fit-content;
                    user-select: none;
                    margin:  0 auto;
                }
            }
        }
    }
    .copyright {
        margin: 40px auto 20px;
        color: #fff;
        .beian-link {
            text-decoration: none;
        }
    }
</style>
